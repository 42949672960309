export const mainMenu = [
  {
    mainTitle: "О компании",
    link: "",
    submenu: [
      {
        title: "Информация о компании",
        link: "/about",
      },
      {
        title: "История",
        link: "/history",
      },
      {
        title: "Цели и задачи",
        link: "/goals",
      },
      {
        title: "Основные направления деятельности",
        link: "/mainDirection",
      },
      {
        title: "Раскрытие информации",
        link: "/informationDisclosures",
      },
      {
        title: "Антикоррупционная деятельность",
        link: "/antiCorruption",
      },
      {
        title: "Сертификаты",
        link: "/certs",
      },
      {
        title: "Информация по облигациям",
        link: "/informationOnBonds",
      },
    ],
  },

  {
    mainTitle: "Потребителям",
    link: "/consumers",
    submenu: [
      {
        title: "Территория обслуживания сетевой организации",
        link: "/territory",
      },
      {
        title: "Передача электрической энергии",
        link: "/servicePassports",
      },
      {
        title: "Технологическое присоединение",
        link: "/technologicalConnection",
      },
      {
        title: "Коммерческий учет электрической энергии",
        link: "/electricityAccounting",
      },
      {
        title: "Обслуживание потребителей",
        link: "/customerService",
      },
      {
        title: "Нормативные правовые акты",
        link: "/regulatoryLegalActs",
      },
      {
        title: "Отключения электроэнергии",
        link: "/plannedOutages",
      },
      {
        title: "Консолидация электросетевого имущества",
        link: "/consolidationOfElectric",
      },
      {
        title: "Вниманию объединений садоводов",
        link: "/gardeners",
      },
      {
        title: "Зарядные станции для электромобилей",
        link: "/chargingStations",
      },
      {
        title: "Реализация товарно-материальных ценностей",
        link: "/saleOfItems",
      },
      {
        title: "Дополнительные услуги",
        link: "/additionalServices",
      },
      {
        title: "Внимание мошенничество",
        link: "/attentionFraud",
      },
      {
        title: "Производственные программы на 2024 год",
        link: "/productionPrograms",
      },
    ],
  },
  {
    mainTitle: "ИНВЕСТОРАМ",
    link: "/investors",
    submenu: [],
  },
  {
    mainTitle: "Филиалы",
    link: "/filials",
    submenu: [],
  },
  {
    mainTitle: "Пресс-центр",
    link: "",
    submenu: [
      {
        title: "Новости",
        link: "/news",
      },
      {
        title: "Контакты для СМИ",
        link: "/contactForMedia",
      },
      {
        title: "Специальные проекты",
        link: "/specialProjects",
      },
    ],
  },
  {
    mainTitle: "Вакансии",
    // link: "/job",
    link: "/vacancies",
    submenu: [],
  },
  {
    mainTitle: "Контакты",
    link: "",
    submenu: [
      {
        title: "Контактная информация",
        link: "/contact",
      },
      {
        title: "Реквизиты компании",
        link: "/requisites",
      },
    ],
  },
];
